const state = {
    count: 0
};
export default {
    state,
    mutations: {
        increase(state) {
            state.count++;
        },
        decrease(state) {
            state.count--;
        }
    }
};
