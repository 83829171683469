import { createStore } from "vuex";
// import modules from "./modules";
import user from "./modules/user";
import demo from "./modules/demo";
export default createStore({
    modules: {
        demo,
        user
    }
});
