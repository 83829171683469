import { Button } from "vant";
import { List } from "vant";
import { Cell } from "vant";
import { Tabbar } from "vant";
import { TabbarItem } from "vant";
import { Icon } from "vant";
import { NavBar } from "vant";
import { Field } from "vant";
import { Popover } from "vant";
import { ActionSheet } from "vant";
import { Uploader } from "vant";
import { Swipe } from "vant";
import { SwipeItem } from "vant";
import { Tab } from "vant";
import { Tabs } from "vant";
import { SwipeCell } from "vant";
import { Empty } from "vant";
const plugins = [
    Button,
    List,
    Cell,
    Tabbar,
    TabbarItem,
    Icon,
    NavBar,
    Field,
    ActionSheet,
    Uploader,
    Popover,
    Tab,
    Tabs
];
const useComponents = [Swipe, SwipeItem, SwipeCell, Empty];
export const vantPlugins = {
    install: function (vm) {
        plugins.forEach(item => {
            vm.component(item.name, item);
        });
        useComponents.forEach(item => {
            vm.use(item);
        });
    }
};
