import { defineComponent } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "AppContainer",
  props: {
    appbar: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ""
    },
    back: {
      type: String,
      default: ""
    }
  },

  setup(prop, ctx) {
    const router = useRouter();

    const onClickLeft = () => {
      router.back();
      ctx.emit("click-left");
    };

    const onClickRight = () => {
      ctx.emit("click-right");
    };

    return {
      onClickLeft,
      onClickRight
    };
  }

});