import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "container"
};
const _hoisted_2 = {
  key: 0,
  class: "appbar"
};
const _hoisted_3 = {
  class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.appbar ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_van_nav_bar, {
    title: _ctx.title,
    "left-text": "返回",
    "right-text": "",
    "left-arrow": "",
    onClickLeft: _ctx.onClickLeft,
    onClickRight: _ctx.onClickRight
  }, null, 8, ["title", "onClickLeft", "onClickRight"])])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, [_renderSlot(_ctx.$slots, "default")])]);
}